import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/avatar.jpg";
import Tilt from "react-parallax-tilt";
import { FaLinkedinIn } from "react-icons/fa";
import Techstack from "./Techstack";
import Toolstack from "./Toolstack";

function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container fluid className="about-section">
        <h1 className="project-heading">
          Mes <strong className="purple">Compétences </strong>
        </h1>

        <Techstack />

        <h1 className="project-heading">
          <strong className="purple">Logiciels</strong> utilisés
        </h1>
        <Toolstack />
      </Container>
      <Container>

        <Row>
          <Col md={8} className="home-about-description">


            <h1 style={{ fontSize: "2.6em" }}>
              A <span className="purple"> PROPOS </span> DE MOI
            </h1>
            <p className="home-about-body">
              Je m'appelle Jonas Latapie, et je suis développeur web Javascript / TypeScript en freelance
              <br />
              <br />Après une expérience de plus de 4 ans au sein d'une entreprise, je suis maintenant développeur full stack en freelance. 
              Je suis spécialisé dans le front-end avec les bibliothèques JS telles que <b className="purple">React.js </b> 
              mais j'ai aussi une longue utilisation de <b className="purple">Node.js</b> à mon actif.

              <br /><br />
              Mon expérience au sein de Karoil, la société informatique du groupe Autovision, a été très enrichissante pour moi. 
              Nous étions une petite équipe de 3 développeurs travaillant sur plusieurs sites. 
              J'ai donc été amené a créer plusieurs projets en solo me permettant d'acquérir
              une solide connaissance en front-end comme en back-end mais aussi en SEO, création de serveurs web.
            </p>
          </Col>
          <Col md={4} className="myAvtar">
            <Tilt>
              <img src={myImg} className="avatar" alt="avatar" />
            </Tilt>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;
