import React from "react";
import { Col, Row } from "react-bootstrap";
import ProgressBar from 'react-bootstrap/ProgressBar';
import {
  DiJavascript1,
  DiReact,
  DiNodejs,
  DiMongodb,
  DiSymfonyBadge,
  DiWordpress,
  DiGithubBadge,
  DiJqueryLogo
} from "react-icons/di";

function Techstack() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      <Col xs={4} md={3} className="tech-icons">
        <ProgressBar animated now={90} label="Javascript"/>
        <DiJavascript1 />
      </Col>
      <Col xs={4} md={3} className="tech-icons">
        <ProgressBar animated now={90} label="React"/>
        <DiReact />
      </Col>
      <Col xs={4} md={3} className="tech-icons">
        <ProgressBar animated now={80} label="Node.js"/>
        <DiNodejs />
      </Col>
      <Col xs={4} md={3} className="tech-icons">
        <ProgressBar animated now={80} label="Jquery"/>
        <DiJqueryLogo />
      </Col>
      <Col xs={4} md={3} className="tech-icons">
        <ProgressBar animated now={80} label="Symfony"/>
        <DiSymfonyBadge />
      </Col>
      <Col xs={4} md={3} className="tech-icons">
        <ProgressBar animated now={80} label="MongoDb"/>
        <DiMongodb />
      </Col>
      <Col xs={4} md={3} className="tech-icons">
        <ProgressBar animated now={80} label="Wordpress"/>
        <DiWordpress />
      </Col>
      <Col xs={4} md={3} className="tech-icons">
        <ProgressBar animated now={70} label="Github"/>
        <DiGithubBadge />
      </Col>

    </Row>
  );
}

export default Techstack;
