import React, { useRef, useState  } from "react";
import emailjs from '@emailjs/browser';
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import Particle from "../Particle";
import Alert from 'react-bootstrap/Alert';

function Contact (props) {
  
    const form = useRef();
    const [show, updateShow] = useState(false);

    const sendEmail = (e) => {
      e.preventDefault();
      console.log(show)
      updateShow(true);

      emailjs.sendForm('service_fh7m9vb', 'template_88oa6hu', form.current, 'user_NoHPJSV2l5MhIBo720Fcl')
        .then((result) => {
          
        }, (error) => {
            console.log(error.text);
        });
    };

    const AlertCustom = () => {
      return (
        <>
          <Alert show={show} variant="success">
            <Alert.Heading>Email envoyé</Alert.Heading>
          </Alert>
    
          {!show && <Button onClick={() => updateShow(true)}>Show Alert</Button>}
        </>
      );
  }


  return (
    <Container fluid className="contact-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          <strong className="purple">Contact </strong>
        </h1>
        <Form ref={form} onSubmit={sendEmail}>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="formGridPrenom">
              <Form.Label>Prénom *</Form.Label>
              <Form.Control name="user_prenom" required/>
            </Form.Group>

            <Form.Group as={Col} controlId="formGridNom">
              <Form.Label>Nom *</Form.Label>
              <Form.Control name="user_nom" required/>
            </Form.Group>
          </Row>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Email *</Form.Label>
            <Form.Control type="email" name="user_email" required/>
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label>Message *</Form.Label>
            <Form.Control as="textarea" name="message" rows={3} required/>
          </Form.Group>
          <Button type="submit">Envoyer</Button>
        </Form>
        {show ? <AlertCustom /> : <div></div> }
      </Container>
    </Container>
  );
}

export default Contact;
