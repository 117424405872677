import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { FaLinkedinIn } from "react-icons/fa";

function Footer() {
  let date = new Date();
  let year = date.getFullYear();
  return (
    <Container fluid className="footer"> <Container>
      <Row>

          <Col md="5" className="footer-copywright">
            <h3>© {year} Jonas Latapie - Développeur Full Stack Freelance</h3>
          </Col>
          <Col md="5" className="footer-body">
            <ul className="footer-icons">
              <li className="social-icons">
                <a
                  href="https://www.linkedin.com/in/jonas-latapie/"
                  style={{ color: "white" }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaLinkedinIn />
                </a>
              </li>
            </ul>
          </Col>
      </Row> </Container>
    </Container>
  );
}

export default Footer;
