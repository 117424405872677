import React from "react";
import { Col, Row } from "react-bootstrap";
import {
  SiLinux,
  SiVisualstudiocode,
  SiPostman,
  SiPostgresql,
  SiGit,
  SiGitlab,
  SiGitkraken,
  SiTrello
} from "react-icons/si";

function Toolstack() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      <Col xs={4} md={2} className="tech-icons">
        <SiVisualstudiocode />
        <div>VS code</div>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiPostman />
        <div>Postman</div>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiPostgresql />
        <div>PostgreSql</div>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiGit />
        <div>Git</div>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiGitkraken />
        <div>Gitkraken</div>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiGitlab />
        <div>GitLab</div>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiTrello />
        <div>Trello</div>
      </Col>

    </Row>
  );
}

export default Toolstack;
