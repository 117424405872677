import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";

import toituresEtanches from "../../Assets/Projects/toitures_etanches_frontpage.PNG";
import jjNavarro from "../../Assets/Projects/jj_navarro_frontpage.PNG";
import agis from "../../Assets/Projects/agis_frontpage.PNG";
import rdvo from "../../Assets/Projects/rdvo_frontpage.PNG";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          Mes <strong className="purple">Réalisations </strong>
        </h1>
        <p style={{ color: "white" }}>
          Les sites sur lesquels j'ai travaillé
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
        <Col md={4} className="project-card">
            <ProjectCard
              imgPath={rdvo}
              isBlog={false}
              title="Rdvo-Online"
              description="RDV-Online est un site de prise de rendez-vous en ligne pour le contrôle technique automobile pour le réseau Autovision crée en PHP, HTML / CSS, Doctrine et jQuery."
              link="https://ct.rdv-online.fr"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={toituresEtanches}
              isBlog={false}
              title="Toitures étanches"
              description="Site vitrine de l'entreprise de construction et rénovation Toitures Etanches. Seul développeur du site, je m'occupe actuellement de la maintenance et de la campagne adwords du site."
              link="https://toituresetanches.fr"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={jjNavarro}
              isBlog={false}
              title="Jean-Jacques Navarro"
              description="Site du peintre et dessinateur du même nom. From scratch avec back-office, ce site lui permet d'organiser lui même l'affichage de ses oeuvres, et d'informer de ses expositions."
              link="https://jjnavarropeintre.fr/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={agis}
              isBlog={false}
              title="Agis"
              description="Site de l'institut de formation Agis. Site wordpress permettant à l'utilisateur de rajouter lui même facilement ses formations et publications."
              link="https://agis.fr"
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
